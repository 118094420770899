<template>
	<div>
		<div style="opacity: 0;" id="attach_popup_az6hmn" type="primary" @click="openAttachPopupBtn"></div>
		<a-modal class="attach_popup_select_modal" :zIndex="2000" :destroyInClose="true" cancelText="取消" okText="确认"
			:centered="true" :visible="visible" @ok="handleOk" @cancel="handleCancle" title="选择文件" :width="attach_popup_info.pageType == 0?'95%':'86%'"
			:destroyOnClose="true" wrapClassName="full-modal">
			<div class="attach_popup_content">
				<div v-if="attach_popup_info.pageType == 0" class="attach_popup_changBox">
					<div :class="{itemSelected: modalType==1,}" @click="changePage(1)">
						<span></span>
						图片库
					</div>
					<div :class="{itemSelected: modalType==2,}" @click="changePage(2)">
						音视频库
					</div>
					<div :class="{itemSelected: modalType==3,}" @click="changePage(3)">
						文件库
					</div>
				</div>
				<attach_manage :isPageOrModel="isPageOrModel" :modalType="modalType" :multiple="attach_popup_info.multiple"
					@getlistsfromLib="getlistsfromLib">
				</attach_manage>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {
		message
	} from 'ant-design-vue';
	import attach_manage from '../attach_manage/App.vue';
	export default {
		name: 'AttachPopup',
		components: {
			attach_manage,
		},
		props: {},
		data() {
			return {
				modalType: 1,
				visible: false,
				isPageOrModel: 2, //1:页面，2：model.
				attach_popup_info: attach_popup_info,
				libLists: '',
			}
		},
		created() {

		},
		beforeDestroy() {},
		methods: {
			/*打开弹窗*/
			openAttachPopupBtn() {
				this.attach_popup_info = attach_popup_info;
				if (attach_popup_info.pageType != 0) {
					this.modalType = attach_popup_info.pageType;
				}
				this.visible = true;
			},
			changePage(e) {
				this.modalType = e;
			},
			handleOk() {
				let file_keys = [];
				/* 多选 */
				if (attach_popup_info.multiple == 1) {
					if (this.libLists.length == 0) {
						message.error("请先选择附件");
						return false;
					} else {
						for(let i=0;i<this.libLists.length;i++){
							file_keys.push(this.libLists[i].file_key)
						}
					}
				}
				/* 单选 */
				if (attach_popup_info.multiple == 2) {
					if (this.libLists == '') {
						message.error("请先选择附件");
						return false;
					} else {
						file_keys.push(this.libLists.file_key)
					}
				}
				this.visible = false;
				if(typeof attachPopupSelectCallbacki1 == 'function'){
					attachPopupSelectCallbacki1(file_keys)
				}
			},
			handleCancle() {
				this.visible = false;
			},
			getlistsfromLib(e) {
				this.libLists = e;
			},
		},
	}
</script>

<style>
	.ant-image-preview-root .ant-image-preview-mask{
		z-index:2001;
	}
	.ant-image-preview-root .ant-image-preview-wrap {
		z-index:2001;
	}
	.attach_popup_select_modal .ant-btn-primary {
		background: #09bd71;
		border-color: #09bd71;
	}

	.attach_popup_select_modal .ant-btn-primary:hover {
		background: #09bd71;
		border-color: #09bd71;
	}

	.attach_popup_content {
		width: 100%;
		height: calc(100vh - 248px);
		display: flex;
	}

	.itemSelected {
		color: #09bd71;
	}

	.attach_popup_changBox {
		min-width: 140px;
		border-right:1px solid #F0F0F0;
		padding-left: 10px;
		padding-top: 40px;
	}

	.attach_popup_changBox>div {
		padding: 15px 10px;
		box-sizing: border-box;
		line-height: 1;
		cursor: pointer;
		font-size: 14px;
	}

	.attach_popup_changBox>div:hover {
		color: #09bd71;
	}
</style>
